import React, { useState, useContext, useEffect } from 'react';
import ReAddToCartModal from 'components/ReAddToCartModal';
import ConfirmDialog from 'pages/checkout/ConfirmDialog';
import ReFloatingStaticButton from 'components/ReFloatingStaticButton';
import countObjectValues from 'functions/countObjectValues';
import {
  sumTotalPriceWithPrepItem,
  sumTotalPriceWithPrepItemV2,
} from 'functions/sumTotalPriceWithPrepItem';
import CheckoutPage from 'pages/checkout/CheckoutPage';
import { useAuthContext } from 'context/AuthContext';
import { useRealmContext } from 'context/RealmContext';
import { useGeneralContext } from 'context/GeneralContext';
import { useHistory } from 'react-router-dom';
import localStorageNames from 'data/localStorageNames';
import { useTranslation } from 'react-i18next';
import QrCodeDialog from 'pages/checkout/QrCodeDialog';
import LoadingPayment from 'pages/checkout/LoadingPayment';

const TOKEN_VALIDATOR =
  process.env[`REACT_APP_VALIDATION_${process.env.REACT_APP_ENV}`];

export const CartContext = React.createContext([{}, () => {}]);

const initialAddToCartModal = {
  visible: false,
  data: null,
};

const initialCheckoutPage = {
  visible: false,
};

const initialConfirmModal = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  orderMethod: null,
};

const initialConfirmPayment = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  data: null,
};

const initialQrcodeModal = {
  visible: false,
  data: null,
};

const initialSuggestionPage = {
  visible: false,
};

const initialState = {
  cartModal: initialAddToCartModal,
  checkoutPage: initialCheckoutPage,
  confirmModal: initialConfirmModal,
  confirmPayment: initialConfirmPayment,
  qrcodeModal: initialQrcodeModal,
  loadingPayment: false,
  loadingModal: false,
  suggestionPage: initialSuggestionPage,
  cart: {
    items: [],
  },
  realmCart: {
    items: [],
  },
  urlXenditPage: null,
  showTenantBtn: false,
};

const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const {
    hitAPI,
    getCredential,
    getCurrentSalesType,
    fetchProducts,
    getTableName,
    getUserPhone,
    getOutletInfo,
    getOrderMethod,
    getSetting,
    setAuthState,
    getColorApp
  } = useAuthContext();
  const { toastError, getSellBandId } = useGeneralContext();
  const { t } = useTranslation();
  const {
    hitRealmTransaction,
    hitRealmTransaction2,
    checkCurrentTable,
    queryTransaction,
    sendClientPayment,
  } = useRealmContext();

  const ID_OVO = 'ID_OVO';
  const ID_DANA = 'ID_DANA';
  const ID_LINKAJA = 'ID_LINKAJA';
  const ID_SHOPEEPAY = 'ID_SHOPEEPAY';
  const forceReset = () => {
    setState(initialState);
    checkCartItem();
  };

  const isLoggedIn = () => {
    return getCredential() && getCurrentSalesType();
  };

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setLoadingModal = (value) => {
    setContextState({
      loadingModal: value,
    });
  };

  const setConfirmModal = (value, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: value,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const setConfirmPayment = (value, titleDialog, messageDialog, data) => {
    setContextState({
      confirmPayment: {
        visible: value,
        titleDialog: titleDialog,
        messageDialog: messageDialog,
        data: data,
      },
    });
  };

  const setCartItems = (newData) => {
    setContextState({
      cart: {
        items: newData,
      },
    });
  };

  const getCartItems = () => {
    let itemsData = [...getContextState('cart').items];
    return itemsData;
  };

  const setCartItemLocalStorage = (newData) => {
    if (isLoggedIn()) {
      localStorage.setItem(
        localStorageNames.CART_ITEMS,
        JSON.stringify(newData),
      );
    }
  };

  const getCartItemLocalStorage = () => {
    let localCart = localStorage.getItem(localStorageNames.CART_ITEMS);
    return JSON.parse(localCart) || [];
  };

  const setRealmCartItems = (newData) => {
    setContextState({
      realmCart: {
        items: newData,
      },
    });
  };

  const getRealmCartItems = () => {
    let itemsData = [...getContextState('realmCart').items];
    return itemsData;
  };

  const setRealmCartItemLocalStorage = (newData) => {
    if (isLoggedIn()) {
      localStorage.setItem(
        localStorageNames.PAYMENT_ITEMS,
        JSON.stringify(newData),
      );
    }
  };

  const getRealmCartItemLocalStorage = () => {
    let localCart = localStorage.getItem(localStorageNames.PAYMENT_ITEMS);
    return JSON.parse(localCart) || [];
  };

  const setCartModal = (value, data) => {
    setContextState({
      cartModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const setQrcodeModal = (value, data) => {
    setContextState({
      qrcodeModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const getCartModalData = () => {
    return { ...getContextState('cartModal').data };
  };

  const getCartModalVisibility = () => {
    return getContextState('cartModal').visible;
  };

  const setSuggestionPage = (value) => {
    setContextState({
      suggestionPage: {
        visible: value,
      },
    });
  };

  const getSuggestionPage = () => {
    return getContextState('suggestionPage').visible;
  };

  const setCheckoutPage = (value) => {
    setContextState({
      checkoutPage: {
        visible: value,
      },
    });
  };

  const getBadgeCount = () => {
    let count =
      countObjectValues(getCartItems(), 'quantity') +
      countObjectValues(getRealmCartItems(), 'quantity');
    return count;
  };

  const getXenditPage = () => {
    const urlXendit = getContextState('urlXenditPage');
    return urlXendit;
  };

  const changePrepItemQuantity = (
    uuidProduct,
    timeStampProduct,
    uuidGroup,
    index,
    qty,
  ) => {
    let items = getCartItems();
    let itemIndex = items.findIndex(
      (x) => x.uuid_product === uuidProduct && x.timestamp === timeStampProduct,
    );
    if (itemIndex > -1) {
      let indexGroup = items[itemIndex].prep_group.findIndex(
        (x) => x.uuid_group === uuidGroup,
      );
      if (indexGroup > -1) {
        items[itemIndex].prep_group[indexGroup].prep_item[index].quantity = qty;
        setCartItems(items);
      }
    }
  };

  const changeRealmPrepItemQuantity = (_id, index, qty) => {
    let items = getRealmCartItems();
    let itemIndex = items.findIndex((x) => x._id === _id);
    if (itemIndex > -1) {
      items[itemIndex].prep_items[index].quantity = qty;

      setRealmCartItems(items);
    }
  };

  const removeItemInCart = (item) => {
    if (item._id) {
      let items = getRealmCartItems();
      items = items.filter((x) => x._id !== item._id);

      setRealmCartItems(items);
    } else {
      let items = getCartItems();
      items = items.filter((x) => x.timestamp !== item.timestamp);
      setCartItems(items);
    }
  };

  const totalPrice = () => {
    let subTotal = 0;
    getCartItems().map((item) => {
      subTotal += sumTotalPriceWithPrepItem(
        item,
        item.quantity,
        getSellBandId(),
      );
      return null;
    });
    getRealmCartItems().forEach((item) => {
      subTotal += item.quantity * item.amount;
      subTotal += sumTotalPriceWithPrepItemV2(item, item.quantity);
    });
    return subTotal;
  };

  const totalQty = () => {
    let qty =
      countObjectValues(getCartItems(), 'quantity') +
      countObjectValues(getRealmCartItems(), 'quantity');
    return qty;
  };

  const changeCartItemQuantity = (item, quantity) => {
    let items = [];
    let itemIndex = -1;
    //Percabangan untuk memisahkan item dari realm atau bukan
    //jika item._id true berarti item mengambil dari realm jika tidak mengambil dari local
    if (item._id) {
      items = getRealmCartItems();
      itemIndex = items.findIndex((x) => x._id === item._id);
      if (itemIndex !== -1) {
        items[itemIndex].quantity = quantity;
        setRealmCartItems(items);
      }
    } else {
      items = getCartItems();
      itemIndex = items.findIndex(
        (x) =>
          x.uuid_product === item.uuid_product &&
          x.timestamp === item.timestamp,
      );

      if (itemIndex !== -1) {
        items[itemIndex].quantity = quantity;
        setCartItems(items);
      }
    }
  };

  const addToCart = (item, isUsingMock) => {
    let localPrepGroup = [];

    if (item.is_variant) {
      const variantChecked = item.variant_item.filter((data) => data.checked);
      if (variantChecked && variantChecked.length > 0) {
        if (
          variantChecked[0].prep_group &&
          variantChecked[0].prep_group.length > 0
        ) {
          localPrepGroup = [...variantChecked[0].prep_group];
        }
      }
    } else {
      localPrepGroup = [...(item.prep_group || [])];
    }
    // CEK DAN FILTER PREP GROUP YANG TERISI SAJA
    if (localPrepGroup && localPrepGroup.length > 0) {
      localPrepGroup = localPrepGroup
        .map((group) => {
          let localPrepItem = [...group.prep_item.filter((x) => x.checked)];
          group.prep_item = localPrepItem;
          if (group.prep_item.length > 0) {
            return group;
          }
          return null;
        })
        .filter((y) => y);
    }

    if (isUsingMock) {
      // HANYA KETIKA MENGGUNAKAN TOMBOL MOCK DI DEV PANEL
      setCartItems([...getCartItemLocalStorage(), item]);
    } else {
      setCartItems([...getCartItems(), item]);
    }

    if (item.is_have_suggestion) {
      const dataSuggestion = item.uuid_product;
      setSuggestionPage(true);
      history.push('/suggestion', dataSuggestion);
    }
  };

  const sendTransaction = async (orderMethod, parameter) => {
    if (orderMethod === 'payment_order') setLoadingModal(true);

    let credential = getCredential() || {};
    let typesales = getCurrentSalesType() || {};
    let cartItem = [...getCartItems()];

    let data = {};
    let tokenValidation = {};

    if (Array.isArray(cartItem) && cartItem.length > 0) {
      data = {
        uuid_table: credential.uuid || credential.table,
        parameter: parameter,
        uuid_sales_type: typesales.id,
        order_items: cartItem.map((item) => {
          let prepGroup = [];
          let productId = '';
          let productName = '';
          //cek jika type variant
          if (item.is_variant) {
            const variantChecked = item.variant_item.filter(
              (data) => data.checked,
            );
            productId = variantChecked[0].uuid_product;
            productName = variantChecked[0].plu_name;
            if (variantChecked && variantChecked.length > 0) {
              if (
                variantChecked[0].prep_group &&
                variantChecked[0].prep_group.length > 0
              ) {
                prepGroup = [...variantChecked[0].prep_group];
              }
            }
          } else {
            prepGroup = item.prep_group;
            productId = item.uuid_product;
            productName = item.plu_name;
          }
          // Spread semua prep group kedalam 1 array of prep item
          let selectedPrepItem = [];
          if (Array.isArray(prepGroup) && prepGroup.length > 0) {
            // SPREAD PREP GROUP NYA
            prepGroup.map((group) => {
              selectedPrepItem = [...selectedPrepItem, ...group.prep_item];
              // menghindari error eslint (array.map harus return sesuatu)
              return null;
            });

            // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
            selectedPrepItem = selectedPrepItem.map((prepItem) => {
              let localPrepItem = { ...prepItem };
              delete localPrepItem.checked;
              delete localPrepItem.price;
              delete localPrepItem.use_quantity;
              delete localPrepItem.sell_bands;
              localPrepItem.quantity =
                localPrepItem.quantity !== null && localPrepItem.quantity > 0
                  ? localPrepItem.quantity * item.quantity
                  : 1;
              localPrepItem.product_name = localPrepItem.plu_name;
              return localPrepItem;
            });
          }

          return {
            uuid_product: productId,
            plu_name: item.plu_name,
            product_name: productName,
            quantity: item.quantity,
            note: item.additional_request || [],
            prep_items: selectedPrepItem,
          };
        }),
      };

      // CHECK PRODUCT AND TOKEN
      tokenValidation = await hitAPI(TOKEN_VALIDATOR, 'POST', credential, data);
    } else {
      // untuk payment order, jika tidak ada data baru (item product yang dipilih user) maka tidak dilakukan validasi
      // jadi tokenValidation diberi nilai
      tokenValidation.error = false;
    }

    const getQtyPrepItem = (prepItem, item) => {
      const fromViewBill = localStorage.getItem(localStorageNames.IS_VIEW_BILL);
      const qty = fromViewBill
        ? (prepItem.baseQuantity || prepItem.quantity) * item?.quantity
        : prepItem?.quantity * item?.quantity;

      return qty || 0;
    };

    // TOKEN MASIH VALID
    if (tokenValidation && tokenValidation.error === false) {
      let typesales = getCurrentSalesType() || {};
      let outletInfo = JSON.parse(getOutletInfo());
      let cartItem = [...getCartItems(), ...getRealmCartItems()];

      let data = {
        table_id: '',
        table_name: getTableName() || tokenValidation.result.parameter || '',
        sales_type: getCurrentSalesType().type_sales_id.toString(),
        sell_band: typesales.sell_band.toString(),
        no_telp: getUserPhone(),
        type_bill: getOrderMethod(),
        order_items: cartItem.map((item) => {
          let prepGroup = [];
          let pluNumber = '';
          let productName = '';
          let _id = item._id;

          if (item.is_variant) {
            const variantChecked = item.variant_item.filter(
              (data) => data.checked,
            );
            pluNumber = variantChecked[0].plu_number;
            productName = variantChecked[0].plu_name;
            if (variantChecked && variantChecked.length > 0) {
              if (
                variantChecked[0].prep_group &&
                variantChecked[0].prep_group.length > 0
              ) {
                prepGroup = [...variantChecked[0].prep_group];
              }
            }
          } else {
            prepGroup = item.prep_group || item.prep_items;
            pluNumber = item.plu_number;
            productName = item.plu_name;
          }

          // Spread semua prep group kedalam 1 array of prep item
          let selectedPrepItem = [];
          if (Array.isArray(prepGroup) && prepGroup.length > 0) {
            // SPREAD PREP GROUP NYA
            prepGroup.map((group) => {
              if (group._id && group._id !== null && group._id !== '') {
                selectedPrepItem = [...selectedPrepItem, group];
              } else {
                selectedPrepItem = [...selectedPrepItem, ...group.prep_item];
              }
              // menghindari error eslint (array.map harus return sesuatu)
              return null;
            });
            // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
            selectedPrepItem = selectedPrepItem.map((prepItem) => {
              let localPrepItem = { ...prepItem };
              delete localPrepItem.checked;
              delete localPrepItem.price;
              delete localPrepItem.use_quantity;
              delete localPrepItem.sell_bands;
              delete localPrepItem.is_active;
              delete localPrepItem.uuid_product;
              delete localPrepItem._id;
              delete localPrepItem._partition;
              delete localPrepItem.amount;
              localPrepItem.quantity =
                localPrepItem.quantity !== null && localPrepItem.quantity > 0
                  ? getQtyPrepItem(prepItem, item)
                  : 1;
              return localPrepItem;
            });
          }

          let type = '';
          let subProduct = [];

          if (item.is_package || item.type === 'package') {
            type = 'package';
            subProduct = item.sub_product;
          } else if (item.is_variant) {
            type = 'variant';
          } else {
            type = 'product';
          }

          return {
            _id: _id,
            plu_number: pluNumber,
            plu_name: productName,
            quantity: item.quantity,
            note: item.additional_request || [],
            prep_items: selectedPrepItem,
            type,
            sub_product: subProduct,
            signature_token: item.signature_token,
          };
        }),
      };

      // 1. CEK DULU MEJANYA APAKAH CLOSE ATAU HOLD (KALAU CLOSE, BIKIN TRANSAKSI BARU, KALAU HOLD JOINKAN DENGAN YANG HOLD)
      // ATAU JIKA TRANSAKSI TYPE NYA PERSONAL PASTI BUAT TRANSAKSI BARU
      let currentTable = await checkCurrentTable();
      let currentTransactionType = getOrderMethod();
      if (
        currentTable &&
        currentTable.status !== 'close' &&
        (currentTransactionType === 'normal_order' ||
          currentTransactionType === 'waiter_order')
      ) {
        let oldTransaction = await queryTransaction({
          status: { $nin: ['close', 'expired'] },
          type_bill: getOrderMethod(),
        });

        if (
          oldTransaction &&
          Array.isArray(oldTransaction) &&
          oldTransaction.length > 0
        ) {
          data._id = oldTransaction[0]._id;
        }
      } else {
        // untuk transaksi tipe personal / payment
        const billId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
        if (billId !== null && billId !== '') {
          data._id = billId;
        }
      }

      // 2. SETELAH DICEK, KIRIM TRANSAKSI SESUAI DENGAN DETAIL DITAHAP 1
      if (orderMethod === 'normal_order' || orderMethod === 'waiter_order') {
        let result = await hitRealmTransaction(data);
        if (result) {
          clearCart();
          const response = { status: 'success' };
          return JSON.stringify(response);
        } else {
          history.replace('/invalid-token');
          const response = { status: 'error' };
          return JSON.stringify(response);
        }
      }
      if (orderMethod === 'payment_order') {
        //is_view_bill set true sebagai tanda jika sudah masuk ke viewbill
        //digunakan untuk handle qty prepitem setelah balik dari viewbill
        localStorage.setItem(localStorageNames.IS_VIEW_BILL, true);
        let result = await hitRealmTransaction2(data);
        if (result) {
          localStorage.setItem(localStorageNames.VIEW_BILL_ID, result);
          history.push('/view-bill');
        } else {
          history.replace('/invalid-token');
        }
      }
      if (orderMethod === 'quick_order' || orderMethod === 'tenant_order') {
        data = { ...data, parameter: tokenValidation.result.parameter || '' };

        let result = await hitRealmTransaction(data);
        if (result) {
          clearCart();
          const response = {
            status: 'success',
            parameter: tokenValidation.result.parameter,
          };
          return JSON.stringify(response);
        } else {
          history.replace('/invalid-token');
          const response = { status: 'error' };
          return JSON.stringify(response);
        }
      }
    } else {
      if (tokenValidation.status === 401) {
        history.replace('/invalid-token');
      } else {
        if (tokenValidation.messages && tokenValidation.messages.length > 0) {
          // JIKA DATA NOT FOUND, MAKA KELUARKAN POP UP DAN FETCH PRODUK TERBARU
          if (tokenValidation.messages[0].includes('data not found')) {
            let productName = tokenValidation.messages[0].split('"');
            toastError(
              t('itemNotFound', {
                product: productName[1],
              }),
            );
            fetchProducts(getCredential());
          } else {
            let message;
            tokenValidation.messages.forEach((data) => {
              message = data + ' \n';
            });
            toastError(message);
          }
        }
      }
      const response = { status: 'error' };
      return JSON.stringify(response);
    }

    if (orderMethod === 'payment_order') setLoadingModal(false);
  };

  const getUrlCheckout = (data) => {
    const actions = data.actions;
    switch (data.channel_code) {
      case ID_LINKAJA:
        return actions.mobile_web_checkout_url;
      case ID_SHOPEEPAY:
        return actions.mobile_deeplink_checkout_url;
      case ID_DANA:
        return actions.mobile_web_checkout_url;
      default:
    }
  };

  const createChargeEWallet = async () => {
    const SEND_CHARGE_EWALLET =
      process.env[
        `REACT_APP_CREATE_EWALLET_CHARGES_${process.env.REACT_APP_ENV}`
      ];
    handleCloseConfirmPayment();
    setContextState({ loadingPayment: true });

    const response = await hitAPI(
      SEND_CHARGE_EWALLET,
      'POST',
      getCredential(),
      getContextState('confirmPayment').data,
    );

    if (response !== undefined && response !== null) {
      if (response.status === 200) {
        const data = response.result.data;
        const chargeAmount = parseFloat(data.charge_amount).toFixed(0);
        const captureAmount = parseFloat(data.capture_amount).toFixed(0);
        const subFunctionId = response.result.SubFunctionID;
        const newDataPayment = {
          ...response.result.data,
          charge_amount: chargeAmount,
          capture_amount: captureAmount,
        };

        handleCloseCheckoutPage();
        clearOrderCart();
        setRealmCartItemLocalStorage([]);

        const transId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
        const result = await sendClientPayment(
          newDataPayment,
          transId,
          subFunctionId,
        );
        if (result) {
          //Selain pembayaran menggunakan ovo akan menerima url untuk checkout pembayaran
          //jika pembayaran menggunakan OVO akan mendapatkan notif di aplikasi

          localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
          localStorage.removeItem(localStorageNames.VIEW_BILL_ID);

          if (data.channel_code !== ID_OVO) {
            await window.open(getUrlCheckout(data), '_self');
          }
          setTimeout(() => {
            setContextState({ loadingPayment: false });
            if (getOrderMethod() === 'tenant_order') {
              history.replace('/tenant-page');
            } else {
              history.replace('/menu-catalogue');
            }
          }, 3000);
        }
      } else {
        setContextState({ loadingPayment: false });

        let messages = '';
        if (response.messages && Array.isArray(response.messages)) {
          response.messages.map((data) => {
            messages += data + '\n';
            return null;
          });
        } else {
          messages = response.messages;
        }
        toastError(messages);
      }
    } else {
      history.replace('/session-expired');
    }
  };

  const clearCart = () => {
    setCartItems([]);
    setCartItemLocalStorage([]);
  };

  const clearRealmCart = () => {
    setRealmCartItems([]);
    setRealmCartItemLocalStorage([]);
  };

  const clearOrderCart = () => {
    localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
    clearRealmCart();
    clearCart();
  };

  const handleOpenCheckoutPage = () => {
    if (getSuggestionPage()) {
      setSuggestionPage(false);
    }
    setCheckoutPage(true);
  };

  const backToTenantList = () => {
    let setting = getSetting();
    setting.tenantId = null;
    setting.tenantName = null;
    localStorage.setItem(localStorageNames.SETTING, JSON.stringify(setting));
    setContextState({ showTenantBtn: false });
    setAuthState({ productTenant: {} });
    setAuthState({ productsTenant: [] });
    history.replace('/tenant-page', { data: getContextState('cart') });
  };

  const handleCloseCheckoutPage = () => {
    setCheckoutPage(false);
  };

  const handleOpenConfirmModal = (orderMethod, parameter) => {
    setConfirmModal(true, orderMethod, parameter);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false, null, null);
  };

  const handleCloseCartModal = (param) => {
    const isDirectOrder = getSetting().isDirectOrder;
    if (!isDirectOrder && !getCartModalData().isSuggestionPage) {
      const data = getContextState('cartModal').data;
      const suggestion = data.is_have_suggestion;
      if (suggestion && param !== 'close') {
        setSuggestionPage(true);
      }
    }
    setCartModal(false, null);
  };

  const handleCloseQrcodeModal = () => {
    setQrcodeModal(false, null);
  };

  const handleCloseConfirmPayment = () => {
    setConfirmPayment(false);
  };

  const checkCartItem = () => {
    // CEK LOCALSTORAGE KALAU ADA VALUE CART NYA, MAKA MASUKKAN KEDALAM STATE
    let LSCart = getCartItemLocalStorage();
    if (isLoggedIn() && LSCart && LSCart.length > 0) {
      setCartItems(LSCart);
    }
    let LSRealmCart = getRealmCartItemLocalStorage();
    if (isLoggedIn() && LSRealmCart && LSRealmCart.length > 0) {
      setRealmCartItems(LSRealmCart);
    }
  };

  useEffect(() => {
    forceReset();
    checkCartItem();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCartItems().length === 0 && getRealmCartItems().length === 0) {
      handleCloseCheckoutPage();
    }
    // KETIKA ADA PERUBAHAN DI STATE, UPDATE JUGA LOCALSTORAGENYA
    setCartItemLocalStorage(
      getCartItems().length > 0 ? getCartItems() : getCartItemLocalStorage(),
    );
    setRealmCartItemLocalStorage(getRealmCartItems());
    // eslint-disable-next-line
  }, [getContextState('cart').items, getContextState('realmCart').items]);

  return (
    <CartContext.Provider
      value={{
        getContextState,
        setContextState,
        setRealmCartItems,
        setCartModal,
        setQrcodeModal,
        getCartModalVisibility,
        addToCart,
        setCheckoutPage,
        changeCartItemQuantity,
        clearCart,
        removeItemInCart,
        forceReset,
        handleOpenCheckoutPage,
        createChargeEWallet,
        setConfirmPayment,
        getSuggestionPage,
        setSuggestionPage,
        clearOrderCart,
        setRealmCartItemLocalStorage,
        getXenditPage,
      }}>
      {children}

      {getCartModalVisibility() && (
        <ReAddToCartModal
          isSuggestion={getSuggestionPage()}
          dataSuggestion={getContextState('cartModal').data.uuid_product}
          closeModal={(param) => handleCloseCartModal(param)}
          item={getCartModalData()}
        />
      )}

      {getContextState('qrcodeModal').visible && (
        <QrCodeDialog closeModal={() => handleCloseQrcodeModal()} />
      )}

      {getContextState('showTenantBtn') && isLoggedIn() && (
        <ReFloatingStaticButton
          totalPrice={totalPrice()}
          badgeCount={getBadgeCount()}
          onClick={() => handleOpenCheckoutPage()}
          onClickBtnTenant={() => backToTenantList()}
        />
      )}

      {getContextState('checkoutPage').visible && (
        <CheckoutPage
          changeItemQty={(item, qty) => changeCartItemQuantity(item, qty)}
          removeItemInCart={(item) => removeItemInCart(item)}
          cartItem={getCartItems()}
          realmCartItem={getRealmCartItems()}
          totalPrice={totalPrice()}
          totalQty={totalQty()}
          clearCart={() => clearOrderCart()}
          closeModal={() => handleCloseCheckoutPage()}
          onConfirm={(orderMethod, parameter) =>
            handleOpenConfirmModal(orderMethod, parameter)
          }
          onViewBill={(orderMethod) => sendTransaction(orderMethod, null)}
          changePrepItemQuantity={(
            uuidProduct,
            timeStampProduct,
            uuidGroup,
            index,
            qty,
          ) =>
            changePrepItemQuantity(
              uuidProduct,
              timeStampProduct,
              uuidGroup,
              index,
              qty,
            )
          }
          changeRealmPrepItemQuantity={(_id, index, qty) =>
            changeRealmPrepItemQuantity(_id, index, qty)
          }
        />
      )}

      {getContextState('confirmModal').visible && (
        <ConfirmDialog
          titleDialog={getContextState('confirmModal').titleDialog}
          messageDialog={getContextState('confirmModal').messageDialog}
          closeModal={() => handleCloseConfirmModal()}
          confirmTransaction={async () => {
            return await sendTransaction(
              getContextState('confirmModal').orderMethod,
              getContextState('confirmModal').parameter || '',
            );
          }}
          closeQRcode={() => forceReset()}
          setting={getSetting()}
          getColorApp={getColorApp}
        />
      )}

      {getContextState('confirmPayment').visible && (
        // confirm dialog pembayaran Xendit
        <ConfirmDialog
          titleDialog={getContextState('confirmPayment').titleDialog}
          messageDialog={getContextState('confirmPayment').messageDialog}
          closeModal={() => handleCloseConfirmPayment()}
          confirmTransaction={() => {
            createChargeEWallet();
          }}
          setting={getSetting()}
        />
      )}
      {getContextState('loadingPayment') && <LoadingPayment />}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const value = useContext(CartContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default CartProvider;
