import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  Grid,
  Box,
  GridList,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { GTranslate } from '@material-ui/icons';
import ReHashLinkScroll from 'components/ReHashLinkScroll';
import ReDropdownLang from 'components/ReDropdownLang';
import { useGeneralContext } from 'context/GeneralContext';
import { useAuthContext } from 'context/AuthContext';
import ReFooter from 'components/ReFooter';
import { makeStyles } from '@material-ui/core/styles';
import localStorageNames from 'data/localStorageNames';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getDefaultLogo, getLogoUrlPath } from 'functions/getImageUrlPath';
import packageJson from '../../../package.json';

const useStyles = makeStyles({
  paper: {
    maxWidth: '80%',
    overflowX: 'hidden',
  },
  paperBigScreen: {
    left: `calc((100% - ${theme.containerWidth}px) / 2)`,
    maxWidth: '70%',
    overflowX: 'hidden',
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  keterangan: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
  },
});

const ToggleDrawer = ({
  isBigScreen,
  tableName,
  logoUrl,
  onClickCategoryForTenant,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getContextState, closeDrawer, getLocalStorage } = useGeneralContext();
  const { getAuthState, getSetting, getColorApp } = useAuthContext();
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const getCredential = () => {
    return getLocalStorage(localStorageNames.USER) === 'guest'
      ? t('guest')
      : getLocalStorage(localStorageNames.USER);
  };

  // const logout = () => {
  //   clearAllStorage();
  //   setDrawerVisible(false);
  //   window.location.href =
  //     process.env[`REACT_APP_QR_URL_${process.env.REACT_APP_ENV}`];
  // };

  const handleItemClick = (category) => {
    if (
      category.categories &&
      Array.isArray(category.categories) &&
      category.categories.length > 0
    ) {
      let newListCategory =
        categoryList.length > 0 ? categoryList : getAuthState('categoryList');
      let newMaps = newListCategory.map((data) => {
        if (data.uuid_product_category === category.uuid_product_category) {
          return { ...data, isShow: !category.isShow ? true : false };
        } else {
          return { ...data, isShow: data.isShow };
        }
      });
      setCategoryList(newMaps);
    } else {
      closeDrawer();
    }
  };

  const handleCategoryClick = (category, index) => {
    onClickCategoryForTenant(category, index);
    closeDrawer();
  };

  return (
    <Drawer
      open={getContextState('drawer')}
      anchor="left"
      onClose={() => closeDrawer()}
      transitionDuration={200}
      classes={{ paper: (isKiosK !== '1' && isBigScreen) ? classes.paperBigScreen : classes.paper }}>
      <Box
        display="flex"
        flexDirection="column"
        height="94vh"
        style={{ backgroundColor: getColorApp()?.backgroundColor }}>
        <GridList cellHeight={'auto'}>
          <List style={{ width: '100%', padding: 0 }}>
            <Grid item className={classes.logo}>
              <img
                alt="logo"
                src={
                  logoUrl
                    ? logoUrl
                    : getLogoUrlPath()
                    ? getLogoUrlPath()
                    : getDefaultLogo()
                }
                style={{ height: 100 }}
              />
            </Grid>

            <Box className={classes.keterangan}>
              <Typography
                variant={isKiosK === '1' ? 'h5' : 'body1'}
                style={{ color: getColorApp()?.primaryFontColor }}>
                {t('loggedin')}
              </Typography>
              <Typography
                variant={isKiosK === '1' ? 'h5' : 'body1'}
                style={{ color: getColorApp()?.primaryFontColor }}>
                {getCredential()}
              </Typography>
            </Box>
            <Divider />
            <Box className={classes.keterangan}>
              <Typography
                variant={isKiosK === '1' ? 'h5' : 'body1'}
                style={{ color: getColorApp()?.primaryFontColor }}>
                {t('table')}
              </Typography>
              <Typography
                variant={isKiosK === '1' ? 'h5' : 'body1'}
                style={{ color: getColorApp()?.primaryFontColor }}
                className={'textwrapper'}>
                {tableName}
              </Typography>
            </Box>
            <Divider />

            {getSetting() &&
              (getSetting().orderMethod === 'normal_order' ||
                getSetting().orderMethod === 'waiter_code') && (
                <>
                  <ListItem
                    button
                    style={{ color: getColorApp()?.primaryFontColor }}
                    onClick={() => {
                      closeDrawer();
                      history.push('/view-bill');
                    }}>
                    {t('viewBill').toUpperCase()}
                  </ListItem>
                  <Divider />
                </>
              )}
            {getSetting() && getSetting().orderMethod === 'payment_order' && (
              <>
                <ListItem
                  button
                  style={{ color: getColorApp()?.primaryFontColor }}
                  onClick={() => {
                    closeDrawer();
                    history.push('/view-transaction');
                  }}>
                  {t('viewTransaction').toUpperCase()}
                </ListItem>
                <Divider />
              </>
            )}
            {(categoryList.length > 0
              ? categoryList
              : getAuthState('categoryList') || []
            ).map((category, index) => (
              <div key={index}>
                <ReHashLinkScroll
                  key={index}
                  to={category.uuid_product_category}>
                  <ListItem
                    button
                    onClick={() =>
                      getSetting().orderMethod === 'tenant_order'
                        ? handleCategoryClick(category, index)
                        : handleItemClick(category)
                    }>
                    <ListItemText
                      style={{ color: getColorApp()?.primaryFontColor }}>
                      <Box
                        fontSize={
                          isKiosK === '1'
                            ? theme.bodyFontSize
                            : theme.typography.fontSize
                        }>
                        {category.category_name.toUpperCase()}
                      </Box>
                    </ListItemText>
                    {category.categories &&
                      category.categories.length > 0 &&
                      (category.isShow ? (
                        <ExpandLess
                          style={{ color: getColorApp()?.primaryFontColor }}
                        />
                      ) : (
                        <ExpandMore
                          style={{ color: getColorApp()?.primaryFontColor }}
                        />
                      ))}
                  </ListItem>
                </ReHashLinkScroll>
                <Collapse
                  component="li"
                  in={category.isShow}
                  timeout="auto"
                  unmountOnExit>
                  <List disablePadding>
                    {Array.isArray(category.categories) &&
                      category.categories.map((subCategory, index) => (
                        <ReHashLinkScroll
                          key={index}
                          to={subCategory.uuid_product_category}>
                          <ListItem
                            button
                            style={{
                              color: getColorApp()?.primaryFontColor,
                              fontSize:
                                isKiosK === '1'
                                  ? theme.bodyFontSize
                                  : theme.typography.fontSize,
                            }}
                            onClick={() => closeDrawer()}
                            className={classes.nested}>
                            {subCategory.isShow}
                            {subCategory.category_name.toUpperCase()}
                          </ListItem>
                        </ReHashLinkScroll>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))}
            <Divider />
            <ListItem
              button
              style={{
                backgroundColor: getColorApp()?.secondaryThemeColor,
                color: getColorApp()?.primaryFontColor,
                fontWeight: 'bold',
                fontSize:
                  isKiosK === '1'
                    ? theme.bodyFontSize
                    : theme.typography.fontSize,
              }}
              onClick={() => {
                closeDrawer();
                history.push('/feedback');
              }}>
              {getSetting() && getSetting().feedbackText
                ? getSetting().feedbackText.toUpperCase()
                : t('feedback').toUpperCase()}
            </ListItem>
            <Divider />
            <ListItem>
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: 'space-between',
                  paddingTop: theme.spacing(4),
                }}>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <GTranslate
                    style={{
                      marginRight: theme.spacing(1),
                      color: getColorApp()?.primaryFontColor,
                    }}
                    fontSize={isKiosK === '1' ? 'large' : 'small'}
                  />{' '}
                  <Box
                    style={{
                      color: getColorApp()?.primaryFontColor,
                      fontSize:
                        isKiosK === '1'
                          ? theme.bodyFontSize
                          : theme.typography.fontSize,
                    }}>
                    {t('language')}
                  </Box>
                </Grid>
                <Grid item>
                  <ReDropdownLang id="DropdownLang:ToggleDrawer" />
                </Grid>
              </Grid>
            </ListItem>
            {/* <ListItem button onClick={() => logout()}>
              <ExitToApp style={{ marginRight: theme.spacing(1) }} /> {t('logout')}
            </ListItem> */}
          </List>
        </GridList>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        style={{ backgroundColor: getColorApp()?.backgroundColor }}
        height="6vh">
        <ReFooter textColor={getColorApp()?.primaryFontColor} />
        <Box
          textAlign="center"
          fontSize={isKiosK === '1' ? theme.bodyFontSize : 10}
          style={{ color: getColorApp()?.primaryFontColor }}>
          V {packageJson.version}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ToggleDrawer;
